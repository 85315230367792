<template>
  <div :class="$style.root">
    <template v-if="type === 'BOOL'">
      <VRow dense>
        <VCol v-for="(field, index) in values['BOOL']" cols="12" md="6" :key="field.type + index">
          <template v-if="field.readonly || readonly">
            <StaticField :value="field.value" :items="[{value: '1', text: 'Да'}, {value: '0', text: 'Нет'}]" />
          </template>
          <template v-else>
            <VRadioGroup v-model="field.value" row dense class="mt-0" hideDetails="auto">
              <VRadio label="Да" :value="'1'" />
              <VRadio label="Нет" :value="'0'" />
            </VRadioGroup>
          </template>
        </VCol>
      </VRow>
    </template>
    <template v-if="type === 'TEXT'">
      <VRow dense>
        <VCol v-for="(field, index) in values['TEXT']" cols="12" md="6" :key="field.type + index">
          <template v-if="field.readonly || readonly">
            <StaticField :value="field.value" />
          </template>
          <template v-else>
            <VTextarea v-model="field.value" :rules="field.required ? rules.required : null" outlined dense auto-grow rows="1" hideDetails="auto" />
          </template>
        </VCol>
      </VRow>
    </template>
    <template v-if="type === 'LIST'">
      <VRow dense>
        <VCol v-if="multiple" cols="12" md="6">
          <SelectMultiple v-model="values['LIST']" :options="options" :readonly="readonly" />
        </VCol>
        <template v-else>
          <VCol v-for="(field, index) in values['LIST']" cols="12" md="6" :key="field.type + index">
            <template v-if="field.readonly || readonly">
              <StaticField :value="field.value" :items="options" />
            </template>
            <template v-else>
              <VSelect v-model="field.value" :items="options" outlined dense hideDetails="auto" />
            </template>
          </VCol>
        </template>
      </VRow>
    </template>
    <template v-if="type === 'FILE'">
      <VRow dense>
        <VCol cols="12">
          <FileFieldMultiple v-model="values['FILE']" :readonly="readonly" dated />
        </VCol>
      </VRow>
    </template>
    <template v-if="type === 'TEXT_WITH_FILE'">
      <VRow dense>
        <VCol v-for="(field, index) in values['TEXT']" cols="12" md="6" :key="field.type + index">
          <template v-if="field.readonly || readonly">
            <StaticField :value="field.value" />
          </template>
          <template v-else>
            <VTextarea v-model="field.value" outlined dense auto-grow rows="1" hideDetails="auto" />
          </template>
        </VCol>
        <VCol cols="12" md="6">
          <FileFieldMultiple v-model="values['FILE']" :readonly="readonly" dated />
        </VCol>
      </VRow>
    </template>
    <template v-if="type === 'LIST_WITH_FILE'">
      <VRow dense>
        <VCol v-if="multiple" cols="12" md="6">
          <SelectMultiple v-model="values['LIST']" :options="options" :readonly="readonly" />
        </VCol>
        <template v-else>
          <VCol v-for="(field, index) in values['LIST']" cols="12" md="6" :key="field.type + index">
            <template v-if="field.readonly || readonly">
              <StaticField :value="field.value" :items="options" />
            </template>
            <template v-else>
              <VSelect v-model="field.value" :items="options" outlined dense hideDetails="auto" />
            </template>
          </VCol>
        </template>
        <VCol cols="12" md="6">
          <FileFieldMultiple v-model="values['FILE']" :readonly="readonly" dated />
        </VCol>
      </VRow>
    </template>
    <template v-if="type === 'BOOL_WITH_TEXT'">
      <VRow dense>
        <VCol v-for="(field, index) in values['BOOL']" cols="12" :key="field.type + index">
          <template v-if="field.readonly || readonly">
            <StaticField :value="field.value" :items="[{value: '1', text: 'Да'}, {value: '0', text: 'Нет'}]" />
          </template>
          <template v-else>
            <VRadioGroup v-model="field.value" row dense class="mt-0" hideDetails="auto">
              <VRadio label="Да" :value="'1'" />
              <VRadio label="Нет" :value="'0'" />
            </VRadioGroup>
          </template>
        </VCol>
        <template v-if="values['BOOL'][0]['value'] === '1'">
          <VCol v-for="(field, index) in values['TEXT']" cols="12" :key="field.type + index">
            <div class="pt-3">
              <template v-if="field.readonly || readonly">
                <StaticField :value="field.value" />
              </template>
              <template v-else>
                <VTextarea v-model="field.value" outlined dense auto-grow rows="1" hideDetails="auto" />
              </template>
            </div>
          </VCol>
        </template>
      </VRow>
    </template>
    <template v-if="type === 'BOOL_WITH_FILE'">
      <VRow dense>
        <VCol v-for="(field, index) in values['BOOL']" cols="12" :key="field.type + index">
          <template v-if="field.readonly || readonly">
            <StaticField :value="field.value" :items="[{value: '1', text: 'Да'}, {value: '0', text: 'Нет'}]" />
          </template>
          <template v-else>
            <VRadioGroup v-model="field.value" row dense class="mt-0" hideDetails="auto">
              <VRadio label="Да" :value="'1'" />
              <VRadio label="Нет" :value="'0'" />
            </VRadioGroup>
          </template>
        </VCol>
        <template v-if="values['BOOL'][0]['value'] === '1'">
          <VCol cols="12">
            <div class="pt-3">
              <FileFieldMultiple v-model="values['FILE']" :readonly="readonly" dated />
            </div>
          </VCol>
        </template>
      </VRow>
    </template>
  </div>
</template>

<script>
import StaticField from '@/components/general/StaticField/StaticField';
import SelectMultiple from '@/components/general/SelectMultiple/SelectMultiple';
import FileFieldMultiple from '@/components/general/FileFieldMultiple/FileFieldMultiple';
export default {
  name: 'CounterpartyDetailValues',
  components: {
    StaticField,
    SelectMultiple,
    FileFieldMultiple,
  },
  props: {
    type: { type: String },
    values: { type: Object },
    options: { type: Array },
    multiple: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
  },
  data() {
    return {
      rules: {
        required: [
          v => !!v || 'Обязательное поле',
        ]
      },
    }
  },
  computed: {},
  methods: {},
}
</script>

<style module lang="scss">
.root {}
</style>
