<template>
  <div :class="$style.root">
    <h1 v-if="$route.meta.title" class="mb-4">{{$route.meta.title}} #{{counterparty}}</h1>
    <div class="mb-4 clearfix">
      <VBtn depressed @click="$router.push({ name: 'pkopir/CounterpartyListView' })">
        <VIcon>mdi-arrow-left</VIcon>
        Назад к списку
      </VBtn>
    </div>
    <div :class="$style.inner">
      <VProgressLinear v-if="pending" absolute indeterminate color="primary darken-2" />
      <template v-if="current">
        <CounterpartyInfo :value="current" />
        <CounterpartyDetail
            :id="current.id"
            :status="current.status.value"
            :values="current.items"
            :comments="commentsInCurrent"
            :pending="pending"
        />
        <CounterpartyInfo class="mt-6" :value="current" />
        <div class="my-4">
          <VBtn depressed @click="$router.push({ name: 'pkopir/CounterpartyListView' })">
            <VIcon>mdi-arrow-left</VIcon>
            Назад к списку
          </VBtn>
        </div>
        <div class="mt-6">
          <CounterpartyHistory :items="commentsInCurrent" />
        </div>
        <CounterpartyCard label="История согласования" class="mt-6">
          <ApprovalHistory :items="current.approvalHistory" />
        </CounterpartyCard>
      </template>
    </div>
    <VDialog :value="!!question" max-width="900" scrollable persistent>
      <CommentCard :items="commentsInDialog" @delete="onDeleteComment">
        <template v-slot:top>
          <VToolbar flat dark color="primary">
            <VToolbarTitle class="px-2">Комментарии</VToolbarTitle>
            <VSpacer/>
            <VBtn icon dark @click="$router.push({ name: 'pkopir/CounterpartyDetailView', params: { counterparty, question: null }})">
              <VIcon>mdi-close</VIcon>
            </VBtn>
          </VToolbar>
        </template>
        <template v-slot:bottom>
          <VCardActions>
            <div v-if="isReviewer" class="flex-grow-1 px-2 py-4 border-top-1">
              <CommentForm @submit="onSubmitComment" />
            </div>
          </VCardActions>
        </template>
      </CommentCard>
    </VDialog>
  </div>
</template>

<script>
import { get } from 'lodash-es';
import { mapGetters, mapActions } from 'vuex';
import { ROLES } from '@/store/user/enums';
import CommentForm from '@/components/general/CommentForm/CommentForm';
import CommentCard from '@/components/general/CommentCard/CommentCard';
import CounterpartyInfo from '@/components/pkopir/CounterpartyInfo/CounterpartyInfo';
import CounterpartyDetail from '@/components/pkopir/CounterpartyDetail/CounterpartyDetail';
import CounterpartyHistory from '@/components/pkopir/CounterpartyHistory/CounterpartyHistory';
import CounterpartyCard from '@/components/pkopir/CounterpartyCard/CounterpartyCard';
import ApprovalHistory from '@/components/general/ApprovalHistory/ApprovalHistory';
export default {
  name: 'CounterpartyDetailView',
  components: {
    CommentForm,
    CommentCard,
    CounterpartyInfo,
    CounterpartyDetail,
    CounterpartyHistory,
    CounterpartyCard,
    ApprovalHistory,
  },
  props: {
    counterparty: { type: String },
    question: { type: String },
  },
  computed: {
    ...mapGetters({
      pending: 'pkopir/pending',
      current: 'pkopir/counterparty',
      commentsInCurrent: 'pkopir/comments',
      hasRole: 'user/hasRole',
    }),
    isReviewer() {
      return this.hasRole(ROLES.REVIEWER, 'pkopir');
    },
    commentsInDialog() {
      return get(this.commentsInCurrent, this.question, []);
    },
    approvalHistory() {
      return get(this.current, 'approvalHistory', []);
    },
  },
  methods: {
    ...mapActions({
      fetchCounterpartyDetail: 'pkopir/fetchCounterpartyDetail',
      fetchCommentList: 'pkopir/fetchCommentList',
      createComment: 'pkopir/createComment',
      deleteComment: 'pkopir/deleteComment',
    }),
    onSubmitComment(message) {
      const id = this.counterparty;
      const question = this.question;
      this.createComment({ id, question, message }).then(() => {
        this.fetchCommentList({ id });
      });
    },
    onDeleteComment(comments) {
      const id = this.counterparty;
      this.deleteComment({ id, comments }).then(() => {
        this.fetchCommentList({ id });
      });
    },
  },
  watch: {
    counterparty: {
      immediate: true,
      handler(id) {
        if (id) {
          this.fetchCounterpartyDetail({ id });
          this.fetchCommentList({ id });
        }
      }
    }
  }
}
</script>

<style module lang="scss">
.root {
  $header-bg: #efefef;
  $border-color: #dee2e6;
  :global(.v-expansion-panel) {
    border: 1px solid $border-color;
    margin-bottom: -1px;
  }
  .inner {
    position: relative;
  }
}
</style>
