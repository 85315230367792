<template>
  <CounterpartyCard label="Информация о контрагенте">
    <VRow>
      <VCol cols="12" md="6">
        <VRow>
          <VCol v-for="({label, value}, index) in info" cols="12" md="6" :key="index">
            <StaticField :label="label" :value="value" />
          </VCol>
        </VRow>
      </VCol>
      <VCol v-if="isReviewer || isSpectator" cols="12" md="6">
        <VRow>
          <VCol v-for="({label, value}, index) in simple ? mark.simple : mark.full" cols="12" md="4" :key="index">
            <StaticField :label="label" :value="value" />
          </VCol>
        </VRow>
        <VCheckbox class="mt-2" v-model="simple" label="Без учета общих вопросов" />
      </VCol>
    </VRow>
  </CounterpartyCard>
</template>

<script>
import { mapGetters } from 'vuex';
import { ROLES } from '@/store/user/enums';
import StaticField from '@/components/general/StaticField/StaticField';
import CounterpartyCard from '@/components/pkopir/CounterpartyCard/CounterpartyCard';
import {get} from 'lodash-es';
export default {
  name: 'CounterpartyInfo',
  components: {
    StaticField,
    CounterpartyCard,
  },
  props: {
    value: { type: Object },
  },
  data() {
    return {
      simple: false,
    }
  },
  computed: {
    ...mapGetters({
      hasRole: 'user/hasRole',
    }),
    isReviewer() {
      return this.hasRole(ROLES.REVIEWER, 'pkopir');
    },
    isSpectator() {
      return this.hasRole(ROLES.SPECTATOR, 'pkopir');
    },
    info() {
      return [
        {label: 'Статус', value: this.value.status.text},
        {label: 'ИНН', value: this.value.inn},
        {label: 'Название КА', value: this.value.name},
      ]
    },
    mark() {
      return {
        full: [
          {label: 'Средняя профильная оценка ПР', value: get(this.value, 'mark.full.average')},
          {label: 'Общая квалификационная оценка', value: get(this.value, 'mark.full.general')},
          {label: 'Интегральная комплексная оценка ПР', value: get(this.value, 'mark.full.integral')},
          {label: 'Комплексная оценка ИИ', value: get(this.value, 'mark.full.complexEngineer')},
          {label: 'Экспертная оценка', value: get(this.value, 'mark.full.expertMark')},
        ],
        simple: [
          {label: 'Средняя профильная оценка ПР', value: get(this.value, 'mark.simple.average')},
          {label: 'Общая квалификационная оценка', value: get(this.value, 'mark.simple.general')},
          {label: 'Интегральная комплексная оценка ПР', value: get(this.value, 'mark.simple.integral')},
          {label: 'Комплексная оценка ИИ', value: get(this.value, 'mark.simple.complexEngineer')},
          {label: 'Экспертная оценка', value: get(this.value, 'mark.simple.expertMark')},
        ]
      }
    }
  }
}
</script>

<style module lang="scss">

</style>
